import React, {  useRef } from 'react';
import Select, { components } from 'react-select';

import { DisplayMode } from '../ThemeContext';
import {useReactSelectSwitcher, SingleOptionType} from './useReactSelectSwitcher'
import { FaPalette } from 'react-icons/fa';
import CustomModal from '../CustomModal';


const ReactSelectSwitcher: React.FC = () => {
  const { selectedOption, modalContent,
    options,
    displayMode,
    handleOptionMouseEnter,
    handleOptionMouseLeave,
    handleChange,
    isModalOpen} = useReactSelectSwitcher();


  const selectRef = useRef<HTMLDivElement>(null);



  const formatOptionLabel = ({ data }: SingleOptionType) => {
    const labelContent = {
      [DisplayMode.Text]: <div>{data.name}</div>,
      [DisplayMode.Icon]: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={data.iconImage} alt={data.name} style={{ height: '1em', width: '1em', marginRight: '0.5em' }} />
          <span>{data.name}</span>
        </div>
      ),
      [DisplayMode.Both]: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={data.iconImage} alt={data.name} style={{ height: '1em', width: '1em', marginRight: '0.5em' }} />
          <span>{data.name}</span>
        </div>
      ),
    };

    return labelContent[displayMode] || <div>{data.name}</div>;
  };
  let styles = {
    color: "var(--header-link-color)",
    backgroundColor:"var(--header-background-color)",
    option: (provided:any, state: any) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      fontSize: state.isSelected ? "large": '',
      color: state.isSelected ? "var(--header-link-color)": '',
      backgroundColor:state.isSelected ? "var(--header-background-color)" :  "",
      filter:  state.isSelected ? "brightness(90%)": '',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: "var(--header-link-color)",
      backgroundColor:"var(--header-background-color)",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: "1px solid var(--header-link-color)",
     
      boxShadow: "1px solid var(--header-link-color)",
      '&:hover': {
         border: "1px solid var(--header-link-color)"
      },
      color: "var(--header-link-color)",
      backgroundColor:"var(--header-background-color)",
    })
  };

  const CustomOption = (props: any) => {
    const { data, selectedOption } = props;
    let optionClassName = `${data.data.parentName ? 'custom-suboption' : 'custom-option'}`;
    optionClassName += selectedOption.value === data.data.value ? 'selected' : '';
    // console.log(optionClassName, selectedOption.value  )
  
    return (
      <div
        className={optionClassName}
        onMouseEnter={() => handleOptionMouseEnter(data)}
        onMouseLeave={handleOptionMouseLeave}
      >
        <components.Option {...props} />
      </div>
    );
  };
  



  return (
    <div className="select-wrapper" ref={selectRef}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <FaPalette size={24} className="icon-color" style={{ marginRight: '0.5em' }} />
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={options}
          isOptionDisabled={(option) => !option.data.isImplemented}
          formatOptionLabel={formatOptionLabel}
          components={{     Option: (props) => <CustomOption {...props} selectedOption={selectedOption} /> }}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={true}
          className="custom-menu"
          styles={styles}
        />
      </div>
      <CustomModal
        isOpen={isModalOpen}
        content={modalContent}
        handleMouseLeave={handleOptionMouseLeave}
        selectRef={selectRef}
      />
    </div>
  );
};

export default ReactSelectSwitcher;