import React, { useContext } from 'react';
import { ThemeContext } from '../themes/ThemeContext';

const Footer: React.FC = () => {
  const { currentTheme } = useContext(ThemeContext);

  return (
    <footer className={`footer`}>
      <p className='footer__text'>Contact Us at:</p>
      <p className='footer__text'>Email: mo.ibrahimsen@nohaibrahimconsultancy.com</p>
      <p className='footer__text'>Phone: +47 48240032</p>
{/*       <div className='footer__links'>
        <a href="#" className='footer__link'>Privacy Policy</a> | <a href="#" className='footer__link'>Terms of Service</a>
      </div> */}
    </footer>
  );
};

export default Footer;

