// React Component
import React from 'react';
import TechSphere from '../TechSphere/TechSphere'
const Hero: React.FC = () => {
  return (
    <div className="hero">
      <div className="hero-left">
        <h1 className="hero-title">Welcome to Noha Ibrahim Consultancy</h1>
        <p className="hero-text">
         I am a seasoned computational scientist, tech lead and technical/solution architect. I bring to the table 18 years of  
       experience in the development of software for diverse applications, including computational modeling, numerical
        simulations, analytics, and machine learning. Over the past 8 years, I’ve held positions as a senior full-stack
        developer, tech lead, and principal software engineer. In these roles, I’ve worked with and led agile teams
        crafting digital twins and applications tailored for condition monitoring, risk management and assurance purposes 
        across various industries such as Oil&Gas, water, and aviation. I hold a PhD in scientific computing, making me an
        adept computational scientist. I have working experience in a wide array of languages and technology stacks. 
        <br/>  
        <br/> 
        I have taken an interest and acquired a good knowledge in Cybersecurity, having completed Google Cybersecurity Professional
        Certification program.    
        <br/>  
        <br/>  
        My mission is to empower your business with cutting-edge solutions, implementing and 
         seamlessly integrating complex technologies to drive innovation and growth.
        </p>
      </div>
      <div className="hero-right">
      <TechSphere/>
      </div>
    </div>
  );
};

export default Hero;
