import React from 'react';

// Home.jsx

// Home.jsx
import ThemeSwitcher from '../themes/themeSwitcher/ThemeSwitcher';
const Academy: React.FC = () => {
  return (
    <main className='home-content'>
      <h1 className='home-title'>Welcome to Noha Ibrahim Consultancy</h1>
      <div className='home-text'>
  <p className='home-title'>This section is currently under construction.
     Once the courses and materials are ready, they will be available here.
     <br/>
     In the meantime, feel free to explore the theme selector 
    with 15 main themes and 41 sub-themes that suits most spirits.
     This selector is a simple showcasing of best practice configurable, 
     micro-frontend approaches that is easily extendible, reusable across applications, 
     and maintainable. New styles can be added by simply updating a configuration file and including a CSS file (only in the public), 
     all without modifying a single line of core code.
      The implementation leverages strong typing with TypeScript, 
      dynamic theme loading, and the Context API for efficient state management,
       ensuring a seamless and swift user experience. 
       Additionally, the decoupling between theme logic and other components prevents unnecessary re-renders,
        and eliminate coupling</p>
</div>


      <ThemeSwitcher />
    </main>
  );
};

export default Academy;
