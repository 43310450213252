import React from 'react';
import Hero from '../components/Hero'; // Adjust the path based on your file structure
// Home.jsx

// Home.jsx

const Home: React.FC = () => {
  return (
    <main>
      <Hero />   
    </main>
  );
};

export default Home;
