import React, { useRef , useEffect, useState} from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';import { Text, OrbitControls, Line } from '@react-three/drei';
import { Vector3 } from 'three';
import * as THREE from 'three';

interface TechnologyProps {
    name: string;
    position: Vector3;
    color: string; // Add a color prop
  }

const Technology: React.FC<TechnologyProps> = ({ name, position, color  }) => {
  const meshRef = useRef<THREE.Mesh>(null);
  const { camera } = useThree();

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.lookAt(camera.position);
    }
  });

  return (
    <mesh position={position.toArray()} ref={meshRef}>
      <Text
        color={color}
        fontSize={0.3}
        maxWidth={2}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        anchorX="center"
        anchorY="middle"
        // onClick={() => console.log(`${name} clicked`)}
      >
        {name}
      </Text>
    </mesh>
  );
};


const generateSpherePositions = (count: number, radius: number): Vector3[] => {
  const positions: Vector3[] = [];
  for (let i = 0; i < count; i++) {
    const phi = Math.acos(-1 + (2 * i) / count);
    const theta = Math.sqrt(count * Math.PI) * phi;
    positions.push(new Vector3(
      radius * Math.cos(theta) * Math.sin(phi),
      radius * Math.sin(theta) * Math.sin(phi),
      radius * Math.cos(phi)
    ));
  }
  return positions;
};

// Function to generate points for an orbit in a specific plane
const generateOrbitPoints = (radius: number, axis: 'x' | 'y' | 'z', count: number = 100): Vector3[] => {
  const points = [];
  for (let i = 0; i <= count; i++) {
    const radians = (i / count) * 2 * Math.PI;
    let x = 0, y = 0, z = 0;
    
    if (axis === 'x') {
      // Orbit around the x-axis
      y = radius * Math.cos(radians);
      z = radius * Math.sin(radians);
    } else if (axis === 'y') {
      // Orbit around the y-axis
      x = radius * Math.cos(radians);
      z = radius * Math.sin(radians);
    } else if (axis === 'z') {
      // Orbit around the z-axis
      x = radius * Math.cos(radians);
      y = radius * Math.sin(radians);
    }

    points.push(new Vector3(x, y, z));
  }
  return points;
};


const technologies = [
  'CFD', 'Thermodynamics', 'PVT', 'Multiphase Systems', 'UQ', 'ML','HPC',
  'Modeling and Simulaiton','Digital Twins' ,'React', 'Redux',
   'TypeScript', 'JavaScript', 'RESTFull API', "Google Cybersecurity Certification",
  'Azure', 'AWS', 'Docker', 'Kubernetes',
  'Java', 'Springboot', 'C#', '.Net.Core', 'Entity Framework',
   'AngularJS', 'Micro Services', 'OAuth 2.0', 'OData', 
   'Event Driven Architecture',
    'Nginx', 'Webpack', 'Babel', 'Git', 'Jest','Karma',
   'Jasmin', 'SASS',  'Python',  
     'Digital Transformation',
];
// Define the axes as an array of specific literal types
const axes: ('x' | 'y' | 'z')[] = ['x', 'y', 'z'];

const TechSphere: React.FC = () => {
    const [textColor, setTextColor] = useState<string>('var(--home-title-color)'); // Default to an empty string or a sensible default
    const updateTextColor = () => {
      const style = getComputedStyle(document.documentElement);
      const fetchedTextColor = style.getPropertyValue('--home-title-color').trim();
      setTextColor(fetchedTextColor);
  };

  useEffect(() => {
    updateTextColor(); // Initial color setting
    document.addEventListener('themechange', updateTextColor);

    return () => {
        document.removeEventListener('themechange', updateTextColor);
    };
}, [])

  const radius = 4.5; // Adjusted radius for a smaller sphere
  const positions = generateSpherePositions(technologies.length, radius);

  return (
    <Canvas camera={{ position: [0, 0, 15], fov: 50 }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <OrbitControls />
      {positions.map((position, index) => (
        <Technology key={index} name={technologies[index]} position={position} color={textColor} />
      ))}
      {axes.map((axis) => // Use the explicitly typed axes array
        <Line 
          key={axis} 
          points={generateOrbitPoints(radius, axis)} 
          color={textColor}
          lineWidth={0.9} 
          dashed={true} 
          dashSize={0.05} 
          gapSize={0.5} 
        />
      )}
    </Canvas>
  );
};

export default TechSphere